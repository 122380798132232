<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs3 text-left>
            <span class="mainHeader">Profile</span>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <div class="cover-photo">
              <img
                class="background-image"
                src="./../../assets/images/tg.jpg"
              />
              <div class="profile-picture-container">
                <img
                  class="profile-picture"
                  src="./../../assets/images/tg4.webp"
                />
              </div>
            </div>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-end pt-2>
          <!-- <v-flex xs1 pr-2 align-self-center text-right class="tst1"><v-icon text-right color="#3F053C">mdi-pencil</v-icon>
              </v-flex> -->
          <v-flex xs1 pr-2 align-self-center text-right class="tst1">
            <v-icon
              text-right
              color="#3F053C"
              @click="(curItem = userData), (editdialogue = true)"
              >mdi-square-edit-outline</v-icon
            >
            GST :
            <!-- {{ stat }} -->
          </v-flex>
          <v-flex xs1 text-right class="tst1">
            <v-switch
              color="#b234a9"
              v-model="stat"
              hide-details="auto"
              inset 
              @change="changeStat()"
            ></v-switch>
          </v-flex>
        </v-layout>
        <v-layout wrap px-4 pt-10 justify-center>
          <v-flex xs8>
            <v-layout wrap>
              <v-flex xs12 class="mainHeader2"
                >{{ userData.jewelleryName }} Jewellery</v-flex
              >
            </v-layout>
            <v-layout wrap py-2>
              <v-flex xs12 class="tst1" text-left>Location</v-flex>
              <v-flex xs12 class="subhead3" text-left>{{
                userData.location
              }}</v-flex>
            </v-layout>
            <v-layout wrap py-2>
              <v-flex xs12 class="tst1" text-left>Contact</v-flex>
              <v-flex xs12 class="subhead3" text-left>{{
                userData.phone
              }}</v-flex>
            </v-layout>
            <v-layout wrap py-2>
              <v-flex xs12 class="tst1" text-left>Email</v-flex>
              <v-flex xs12 class="subhead3" text-left>{{
                userData.email
              }}</v-flex>
            </v-layout>
            <v-layout wrap py-2>
              <v-flex xs12>
                <v-divider style="border-color:grey; !important"></v-divider>
              </v-flex>
            </v-layout>
            <v-layout wrap py-2>
              <v-flex class="subhead3" text-left>Registration Details</v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs6>
                <v-layout wrap py-2>
                  <v-flex xs12 class="tst1" text-left>GSTIN</v-flex>
                  <v-flex xs12 class="subhead3" text-left>{{
                    userData.GSTIN
                  }}</v-flex>
                </v-layout>
                <v-layout wrap py-2>
                  <v-flex xs12 class="tst1" text-left>TAN</v-flex>
                  <v-flex xs12 class="subhead3" text-left>{{
                    userData.TAN
                  }}</v-flex>
                </v-layout>
                <v-layout wrap py-2>
                  <v-flex xs12 class="tst1" text-left>BIS License</v-flex>
                  <v-flex xs12 class="subhead3" text-left>{{
                    userData.BisLicense
                  }}</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs6>
                <v-layout wrap py-2>
                  <v-flex xs12 class="tst1" text-left
                    >Insurance Policy No</v-flex
                  >
                  <v-flex xs12 class="subhead3" text-left>{{
                    userData.policyNo
                  }}</v-flex>
                </v-layout>
                <v-layout wrap py-2>
                  <v-flex xs12 class="tst1" text-left>Company ID</v-flex>
                  <v-flex xs12 class="subhead3" text-left>{{
                    userData.companyId
                  }}</v-flex>
                </v-layout>
                <v-layout wrap py-2>
                  <v-flex xs12 class="tst1" text-left>State & Code</v-flex>
                  <v-flex xs12 class="subhead3" text-left>{{
                    userData.stateandCode
                  }}</v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap py-2>
              <v-flex xs12>
                <v-divider style="border-color:grey; !important"></v-divider>
              </v-flex>
            </v-layout>
            <v-layout wrap py-2>
              <v-flex class="subhead3" text-left>Bank Details</v-flex>
            </v-layout>
            <!-- <v-layout wrap py-2>
              <v-flex xs1 class="tst1" text-left align-self-center>Switch : </v-flex>
              <v-flex xs2 text-right>
            <v-btn
              block
              dark
              color="#3F053C"
              v-if="GstStat == 'GST'"
              small
              @click="changeGST2()"
            >
              <v-icon color="grey">mdi-sync</v-icon>GST
            </v-btn>
            <v-btn
              block
              dark
              color="#3F053C"
              v-if="GstStat == 'NOGST'"
              small
              @click="changeGST()"
            >
              <v-icon color="3F053C">mdi-sync</v-icon>Non GST
            </v-btn> </v-flex
          >
            </v-layout> -->
            <v-layout wrap>
              <v-flex xs6>
                <v-layout wrap py-2>
                  <v-flex xs12 class="tst1" text-left>Bank Name</v-flex>
                  <v-flex xs12 class="subhead3" text-left>{{
                    userData.bankName
                  }}</v-flex>
                </v-layout>
                <v-layout wrap py-2>
                  <v-flex xs12 class="tst1" text-left>Account No</v-flex>
                  <v-flex xs12 class="subhead3" text-left>{{
                    userData.accountNo
                  }}</v-flex>
                </v-layout>
                <v-layout wrap py-2>
                  <v-flex xs12 class="tst1" text-left>IFSC Code</v-flex>
                  <v-flex xs12 class="subhead3" text-left>{{
                    userData.IFSCCode
                  }}</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs6>
                <v-layout wrap py-2>
                  <v-flex xs12 class="tst1" text-left>Branch</v-flex>
                  <v-flex xs12 class="subhead3" text-left>{{
                    userData.branch
                  }}</v-flex>
                </v-layout>
                <v-layout wrap py-2>
                  <v-flex xs12 class="tst1" text-left>Pan Card No</v-flex>
                  <v-flex xs12 class="subhead3" text-left>{{
                    userData.pancardNo
                  }}</v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <!-- <v-layout wrap>
          <v-flex xs1 text-right>
            <v-btn
              block
              dark
              color="#3F053C"
              v-if="GstStat == 'GST'"
              small
              @click="changeGST2()"
            >
              <v-icon color="grey">mdi-sync</v-icon>GST
            </v-btn>
            <v-btn
              block
              dark
              color="#3F053C"
              v-if="GstStat == 'NOGST'"
              small
              @click="changeGST()"
            >
              <v-icon color="3F053C">mdi-sync</v-icon>Non GST
            </v-btn> </v-flex
          >
        </v-layout> -->
        <v-dialog width="800px" v-model="editdialogue">
          <v-card width="800px">
            <!-- {{ curItem._id }} -->
            <v-layout wrap dense flat justify-center class="body-2">
              <v-flex xs12 pt-4 text-center
                ><span style="color: #3f053c; font-size: 25px"
                  >Edit Profile</span
                ></v-flex
              >
              <v-flex xs8 text-center py-4>
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
            <v-layout wrap pa-4>
              <v-flex pa-4 xs6>
                <v-layout wrap>
                  <v-flex xs12 text-left pb-1
                    ><span class="subhed">Email Address</span></v-flex
                  >
                  <v-flex xs12 pb-3>
                    <v-text-field
                      color="#b234a9"
                      placeholder="Email"
                      outlined
                      :rules="[rules.email]"
                      v-model="curItem.email"
                      type="email"
                      hide-details="auto"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 pb-2 text-left
                    ><span class="subhed">Jewellery Name</span></v-flex
                  >
                  <v-flex xs12 pb-3>
                    <v-text-field
                      color="#b234a9"
                      placeholder="Jewellery Name"
                      outlined
                      :rules="[rules.required]"
                      v-model="curItem.jewelleryName"
                      type="text"
                      hide-details="auto"
                      dense
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex pa-4 xs6>
                <v-layout wrap justify-end>
                  <v-flex xs12 pb-1 text-left
                    ><span class="subhed">Location</span></v-flex
                  >
                  <v-flex xs12 pb-3>
                    <v-text-field
                      color="#b234a9"
                      placeholder="location"
                      outlined
                      v-model="curItem.location"
                      type="text"
                      hide-details="auto"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 pb-2 text-left
                    ><span class="subhed">Phone Number</span></v-flex
                  >
                  <v-flex xs12 align-self-end>
                    <v-text-field
                      color="#b234a9"
                      placeholder="Phone"
                      outlined
                      :rules="phoneRules"
                      v-model="curItem.phone"
                      type="number"
                      hide-details="auto"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <!-- <v-flex xs12><span class="subhed">Address</span></v-flex>
               <v-flex xs12>
             <v-textarea
            placeholder="Address"
            outlined
            v-model="curItem.address"
            type="Address"
            hide-details="auto"
            dense
          ></v-textarea>
           </v-flex> -->
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap py-4 px-8>
              <v-flex xs12>
                <v-divider style="border-color:grey; !important"></v-divider>
              </v-flex>
            </v-layout>
            <v-layout wrap py-2 px-8>
              <v-flex class="subhead3" text-left>Registration</v-flex>
            </v-layout>
            <v-layout wrap pb-4 px-8>
              <v-flex xs4>
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5">GSTIN</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      color="#b234a9"
                      type="text"
                      :rules="[rules.required]"
                      placeholder="Enter GSTIN"
                      v-model="curItem.GSTIN"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5">TAN </span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      color="#b234a9"
                      type="text"
                      placeholder="Enter TAN"
                      v-model="curItem.TAN"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5"> BIS License </span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      color="#b234a9"
                      type="text"
                      placeholder="Enter BIS License"
                      v-model="curItem.BisLicense"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap pb-4 px-8>
              <v-flex xs4>
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5">Insurance Policy No </span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      color="#b234a9"
                      type="text"
                      placeholder="Enter Policy No "
                      v-model="curItem.policyNo"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5"> Company ID </span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      color="#b234a9"
                      type="text"
                      placeholder="Enter Company ID"
                      v-model="curItem.companyId"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5"> State & Code </span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      color="#b234a9"
                      type="text"
                      placeholder="Enter State & Code"
                      v-model="curItem.stateandCode"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap py-4 px-8>
              <v-flex xs12>
                <v-divider style="border-color:black; !important"></v-divider>
              </v-flex>
            </v-layout>
            <v-layout wrap py-2 px-8>
              <v-flex class="subhead3" text-left>Bank Details </v-flex>
            </v-layout>
            <v-layout wrap pb-4 px-8>
              <v-flex xs4>
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5">Bank Name</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      color="#b234a9"
                      type="text"
                      placeholder="Enter Bank Name"
                      v-model="curItem.bankName"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5">Account No</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      color="#b234a9"
                      type="number"
                      placeholder="Enter Account No"
                      v-model="curItem.accountNo"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5">IFSC Code</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      color="#b234a9"
                      type="text"
                      placeholder="Enter IFSC Code"
                      v-model="curItem.IFSCCode"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap pb-4 px-8>
              <v-flex xs4>
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5">Branch</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      color="#b234a9"
                      type="text"
                      placeholder="Enter Branch"
                      v-model="curItem.branch"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-layout wrap px-4>
                  <v-flex xs12 text-left>
                    <span class="subhead5"> Pan Card No </span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      color="#b234a9"
                      placeholder="Enter Pan Card No"
                      v-model="curItem.pancardNo"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!-- <v-flex xs4 px-4 align-self-end >
                <v-btn outlined block color="grey"  @click="$refs.showImage.click()"
                      >Upload Profile Picture
                      <v-item-group>
                        <input
                          v-show="false"
                          placeholder="Update Profile Picture"
                          ref="showImage"
                          id="file"
                          type="file"
                          style="display: none"
                          @change="showImage"
                        />
                      </v-item-group>
                    </v-btn>
              </v-flex> -->
            </v-layout>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="editdialogue = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#3F053C"
                class="body-2 font-weight-bold"
                dark
                @click="editUser()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import store from "./../../store";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      // GstStat: "GST",
      stat: localStorage.getItem("GSTstatus"),
      editdialogue: false,
      curItem: [],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      phoneRules: [
        (v) => !!v || "phone is required",
        (v) => (v && v.length <= 10) || "invalid number",
        (v) => (v && v.length >= 10) || "invalid number",
        (v) => /^\d{10}$/.test(v) || "phone number must be a number",
      ],
      panRules: [
  (value) => !!value || "PAN number is required.",
  (value) => /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value) || "Invalid PAN number.",
],
aadhaarRules: [
  (value) => !!value || "Aadhaar number is required.",
  (value) => /^\d{12}$/.test(value) || "Invalid Aadhaar number.",
],
    };
  },
  // watch:{
  //   model(){
  //     this.changeStat();
  //   }
  // },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  mounted() {
    this.stat =JSON.parse(localStorage.getItem("GSTstatus"));
    console.log("status",localStorage.getItem("GSTstatus"))
  },
  methods: {
    // changeGST() {
    //   this.GstStat = "GST";
    //   console.log("gststst=", this.GstStat);
    //   this.changeStat();
    // },
    // changeGST2() {
    //   this.GstStat = "NOGST";
    //   console.log("gststst=", this.GstStat);
    //   this.changeStat();
    // },
    changeStat() {
      axios({
        url: "/change/taxtype",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: localStorage.getItem("id"),
          taxType: this.stat,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            var stat1 = response.data.data.taxType;
            localStorage.setItem("GSTstatus", stat1);
            store.commit("GSTstatus", stat1);
            console.log("model==", this.stat);
            this.msg = response.data.msg;
            this.showsnackbar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editUser() {
      axios({
        url: "/user/adminedit",
        method: "POST",
        data: {
          id: this.curItem._id,
          location: this.curItem.location,
          email: this.curItem.email,
          jewelleryName: this.curItem.jewelleryName,
          phone: this.curItem.phone,
          GSTIN: this.curItem.GSTIN,
          TAN: this.curItem.TAN,
          stateandCode : this.curItem.stateandCode,
          BisLicense: this.curItem.BisLicense,
          policyNo: this.curItem.policyNo,
          companyId: this.curItem.companyId,
          bankName: this.curItem.bankName,
          accountNo: this.curItem.accountNo,
          IFSCCode: this.curItem.IFSCCode,
          branch: this.curItem.branch,
          pancardNo: this.curItem.pancardNo,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            // if (response.data.id) {
            //   console.log("this.imageArray=",this.imageArray);
            //   if (this.file) {
            //     console.log("file=", this.file);
            //     this.uploadImage();
            //   }
            // }
            this.editdialogue = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.cover-photo {
  position: relative;
  width: 100%;
  height: 300px;
}

.background-image {
  width: 100%;
  height: 100%;
}

.profile-picture-container {
  position: absolute;
  width: 150px;
  height: 150px;
  left: 158px;
  top: 210px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-picture {
  width: 100%;
  height: 100%;
}
</style>
